<div class="modal fade modal-custom custom-white" id="modalRankingDetail" tabindex="-1" role="dialog"
     aria-labelledby="modalRankingDetail" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 666px" role="document">
    <div class="modal-content">
      <div class="modal-blocks">
        <img src="assets/images/block-close.png" alt="Fechar" class="modal-blocks modal-blocks-close"
             data-dismiss="modal" aria-label="Close"/>
      </div>
      <div class="modal-content-box" style="padding: 20px 40px">

        <div class="modal-header my-4 p-0">
          <h2 class="modal-body-title m-0 p-0">Pontuação Detalhada</h2>
        </div>
        <div class="modal-body w-100 p-0">

          <div class="category-col">
            <div class="category-card">
              <div class="category-card-top">
                <h4>{{rankingCategoryDetails.category}}</h4>
              </div>
              <div class="category-card-mid">
                <div class="category-card-mid-col" *ngIf="!isNullOrUndefined(rankingCategoryDetails.objectiveLabel)">
                  <span>{{rankingCategoryDetails.objectiveLabel}}</span>
                  <p style="text-align: start">{{rankingCategoryDetails.objective | currency: 'BRL': ' '}}</p>
                </div>
                <div class="category-card-mid-col" *ngIf="!isNullOrUndefined(rankingCategoryDetails.rankingLabel)">
                  <span>{{rankingCategoryDetails.rankingLabel}}</span>
                  <b>{{rankingCategoryDetails.ranking | currency: 'BRL': ' '}}</b>
                </div>
              </div>
              <div class="category-card-bottom" [ngClass]="{'hide-info' : !rankingCategoryDetails.isPercentage}">
                <div class="bar">
                  <div class="bar-value" [ngStyle]="{'width': rankingCategoryDetails.percentage + '%'}">
                    <span class="bar-value-point">{{rankingCategoryDetails.points}}pt</span>
                    <span class="bar-value-porc">{{rankingCategoryDetails.percentage}}%</span>
                  </div>

                </div>
                <!--<div class="legend">
                  <span>0</span>
                  <span>Pontos</span>
                  <span>120</span>
                </div>-->
              </div>
            </div>

            <div class="category-card-percent">
              <ng-container [ngSwitch]="rankingCategoryDetails.category">
                <ng-container *ngSwitchCase="'Agendamento Online'" [ngTemplateOutlet]="agendamentoonline">
                </ng-container>

                <ng-container *ngSwitchCase="'Autopart'" [ngTemplateOutlet]="autopart">
                </ng-container>

                <ng-container *ngSwitchCase="'Cadência Pedidos Peças'" [ngTemplateOutlet]="cadenciapedidospecas">
                </ng-container>

                <ng-container *ngSwitchCase="'CEM Colocado Q6'" [ngTemplateOutlet]="cemcolocadoq6">
                </ng-container>

                <ng-container *ngSwitchCase="'Compra de Acessórios'" [ngTemplateOutlet]="compraacessorios">
                </ng-container>

                <ng-container *ngSwitchCase="'Compra de Peças'" [ngTemplateOutlet]="performancecomprapeca">
                </ng-container>

                <ng-container *ngSwitchCase="'Compra de Peças & Acessórios'"
                              [ngTemplateOutlet]="performancecomprapecaeacessorio"></ng-container>

                <ng-container *ngSwitchCase="'Evolução de Acessórios'"
                              [ngTemplateOutlet]="accessorios"></ng-container>

                <ng-container *ngSwitchCase="'Grupo Óleo'" [ngTemplateOutlet]="grupooleo">
                </ng-container>

                <ng-container *ngSwitchCase="'Interzone'" [ngTemplateOutlet]="interzone">
                </ng-container>

                <ng-container *ngSwitchCase="'Linha Economy'" [ngTemplateOutlet]="linhaeconomy">
                </ng-container>

                <ng-container *ngSwitchCase="'Óleo'" [ngTemplateOutlet]="oleo">
                </ng-container>

                <ng-container *ngSwitchCase="'Tirreno'" [ngTemplateOutlet]="tirreno"></ng-container>
                <ng-container *ngSwitchCase="'Variação Revisão Planejada'"
                              [ngTemplateOutlet]="variacaorevisaoplanejada"></ng-container>

                <ng-container *ngSwitchCase="'Wurth'" [ngTemplateOutlet]="wurth">
                </ng-container>

                <ng-container *ngSwitchCase="'Bosch'" [ngTemplateOutlet]="bosch">
                </ng-container>

                <ng-container *ngSwitchCase="'Brazzo'" [ngTemplateOutlet]="brazzo">
                </ng-container>

                <ng-container *ngSwitchCase="'Evolução'" [ngTemplateOutlet]="evolucao">
                </ng-container>

                <ng-container *ngSwitchCase="'Evolução de Peças'" [ngTemplateOutlet]="evopecas">
                </ng-container>

                <ng-container *ngSwitchCase="'Óleo Maxi Performance (Grupo)'" [ngTemplateOutlet]="oleoMaxiPerformance">
                </ng-container>

                <ng-container *ngSwitchCase="'STP'" [ngTemplateOutlet]="stp">
                </ng-container>

                <ng-container *ngSwitchCase="'Indice Pós-Vendas'" [ngTemplateOutlet]="posvendas">
                </ng-container>

                <ng-container *ngSwitchCase="'Petroplus STP'" [ngTemplateOutlet]="petrostp">
                </ng-container>

                <ng-container *ngSwitchCase="'Pontuação Geral - Programa Autonomia 2.0'" [ngTemplateOutlet]="autonomia">
                </ng-container>

                <ng-container *ngSwitchCase="'Programa Autonomia 2.0 - 1º Tri.'" [ngTemplateOutlet]="autonomia">
                </ng-container>

                <ng-container *ngSwitchCase="'Programa Autonomia 2.0 - 2º Tri.'" [ngTemplateOutlet]="autonomia">
                </ng-container>

                <ng-container *ngSwitchDefault [ngTemplateOutlet]="empty"></ng-container>
              </ng-container>

            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>





<ng-template #agendamentoonline>
  <table>
    <tr>
      <th>VARIAÇÃO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>De 15% a 19,99% das passagens</td>
      <td class="text-center"><b>50</b></td>
    </tr>
    <tr>
      <td>De 20% a 29,99% das passagens</td>
      <td class="text-center"><b>100</b></td>
    </tr>
    <tr>
      <td>Acima de 30% das passagens</td>
      <td class="text-center"><b>150</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #autopart>
  <table>
    <tr>
      <th>PERCENTUAL</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>De 50% a 69,9%</td>
      <td class="text-center"><b>30</b></td>
    </tr>
    <tr>
      <td>De 70% a 79,9%</td>
      <td class="text-center"><b>50</b></td>
    </tr>
    <tr>
      <td>Acima de 80%</td>
      <td class="text-center"><b>100</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #cadenciapedidospecas>
  <table>
    <tr>
      <th>VARIAÇÃO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>50% até dia 15 (metade do mês)</td>
      <td class="text-center"><b>50</b></td>
    </tr>
    <tr>
      <td>100% até 2 dias úteis</td>
      <td class="text-center"><b>50</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #cemcolocadoq6>
  <table>
    <tr>
      <th>NOTA (M.M TRIM)</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>8,000 a 8,499</td>
      <td class="text-center"><b>50</b></td>
    </tr>
    <tr>
      <td>8,500 a 8,999</td>
      <td class="text-center"><b>100</b></td>
    </tr>
    <tr>
      <td>9,000 a 9,499</td>
      <td class="text-center"><b>250</b></td>
    </tr>
    <tr>
      <td>Acima 9,500</td>
      <td class="text-center"><b>300</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #compraacessorios>
  <table>
    <tr>
      <th>PERCENTUAL</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>De 0,00% a 99,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 100,00% a 104,99%</td>
      <td class="text-center"><b>50</b></td>
    </tr>
    <tr>
      <td>De 105,00% a 109,99%</td>
      <td class="text-center"><b>100</b></td>
    </tr>
    <tr>
      <td>De 110,00% a 119,99%</td>
      <td class="text-center"><b>125</b></td>
    </tr>
    <tr>
      <td>A partir de 120,00%</td>
      <td class="text-center"><b>150</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #performancecomprapeca>
  <table>
    <tr>
      <th>PERCENTUAL</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>De 0,00% a 99,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 100,00% a 104,99%</td>
      <td class="text-center"><b>100</b></td>
    </tr>
    <tr>
      <td>De 105,00% a 109,99%</td>
      <td class="text-center"><b>200</b></td>
    </tr>
    <tr>
      <td>De 110,00% a 119,99%</td>
      <td class="text-center"><b>250</b></td>
    </tr>
    <tr>
      <td>A partir de 120,00%</td>
      <td class="text-center"><b>300</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #performancecomprapecaeacessorio>
  <table>
    <tr>
      <th>PERCENTUAL</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>De 0,00% a 99,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 100,00% a 104,99%</td>
      <td class="text-center"><b>100</b></td>
    </tr>
    <tr>
      <td>De 105,00% a 109,99%</td>
      <td class="text-center"><b>200</b></td>
    </tr>
    <tr>
      <td>De 110,00% a 119,99%</td>
      <td class="text-center"><b>250</b></td>
    </tr>
    <tr>
      <td>A partir de 120,00%</td>
      <td class="text-center"><b>300</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #grupooleo>
  <table>
    <tr>
      <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>0,00% a 89,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 90,00% a 99,99%</td>
      <td class="text-center"><b>80</b></td>
    </tr>
    <tr>
      <td>De 100,00% a 109,99%</td>
      <td class="text-center"><b>120</b></td>
    </tr>
    <tr>
      <td>De 110,00% a 119,99%</td>
      <td class="text-center"><b>160</b></td>
    </tr>
    <tr>
      <td>A partir de 120,00%</td>
      <td class="text-center"><b>200</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #interzone>
  <table>
    <tr>
      <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>100% > acima</td>
      <td class="text-center"><b>100</b></td>
    </tr>
    <tr>
      <td>90,00% a 99,99%</td>
      <td class="text-center"><b>80</b></td>
    </tr>
    <tr>
      <td>85% a 89,99%</td>
      <td class="text-center"><b>60</b></td>
    </tr>
    <tr>
      <td>80% a 84,99</td>
      <td class="text-center"><b>40</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #linhaeconomy>
  <table>
    <tr>
      <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>0 - 89,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>90% - 99,99%</td>
      <td class="text-center"><b>50</b></td>
    </tr>
    <tr>
      <td>100 - 109,99</td>
      <td class="text-center"><b>100</b></td>
    </tr>
    <tr>
      <td>119,99%</td>
      <td class="text-center"><b>150</b></td>
    </tr>
    <tr>
      <td>Acima de 120%</td>
      <td class="text-center"><b>200</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #oleo>
  <table>
    <tr>
      <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>0,00% a 89,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 90,00% a 99,99%</td>
      <td class="text-center"><b>80</b></td>
    </tr>
    <tr>
      <td>De 100,00% a 109,99%</td>
      <td class="text-center"><b>120</b></td>
    </tr>
    <tr>
      <td>De 110,00% a 119,99%</td>
      <td class="text-center"><b>160</b></td>
    </tr>
    <tr>
      <td>A partir de 120,00%</td>
      <td class="text-center"><b>200</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #tirreno>
  <table>
    <tr>
      <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>0,00% a 79,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>80% a 84,99%</td>
      <td class="text-center"><b>40</b></td>
    </tr>
    <tr>
      <td>85,00% a 89,99%</td>
      <td class="text-center"><b>60</b></td>
    </tr>
    <tr>
      <td>90% a 94,99%</td>
      <td class="text-center"><b>140</b></td>
    </tr>
    <tr>
      <td>95% á 99,99%</td>
      <td class="text-center"><b>160</b></td>
    </tr>
    <tr>
      <td>100%</td>
      <td class="text-center"><b>180</b></td>
    </tr>
    <tr>
      <td></td>
      <td class="text-center"></td>
    </tr>
    <tr>
      <td>BÔNUS</td>
      <td class="text-center"><b>20</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #variacaorevisaoplanejada>
  <table>
    <tr>
      <th>VARIAÇÃO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>De 0 a 34,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 35% a 49,99%</td>
      <td class="text-center"><b>50</b></td>
    </tr>
    <tr>
      <td>Acima de 50%</td>
      <td class="text-center"><b>150</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #wurth>
  <table>
    <tr>
      <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
      <th class="text-center">PONTOS</th>
    </tr>
    <tr>
      <td>0,00% a 79,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>De 80% a 89,99%</td>
      <td class="text-center"><b>50</b></td>
    </tr>
    <tr>
      <td>90% á 99,99%</td>
      <td class="text-center"><b>80</b></td>
    </tr>
    <tr>
      <td>>= 100%</td>
      <td class="text-center"><b>120</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #bosch>
  <table class="text-center">
    <tr>
      <th><strong>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</strong></th>
      <th><strong>PONTUAÇÃO</strong></th>
    </tr>
    <tr>
      <td>0% a 89,99%</td>
      <td class="text-center">0</td>
    </tr>
    <tr>
      <td>90% a 109,99%</td>
      <td class="text-center">80</td>
    </tr>
    <tr>
      <td>110,00 % a 119,99%</td>
      <td class="text-center">140</td>
    </tr>
    <tr>
      <td>Acima de 120,00%</td>
      <td class="text-center">180</td>
    </tr>
    <tr>
      <td>Bônus*</td>
      <td class="text-center">20</td>
    </tr>
  </table>
</ng-template>

<ng-template #brazzo>
  <table>
    <tr>
      <th class="text-center" valign="top"><strong>CATEGORIA</strong></th>
      <th class="text-center" valign="top"><strong>OBJETIVO MENSAL</strong></th>
      <th class="text-center" valign="middle" rowspan="5"><strong>100%<br/><br/>40 Pontos</strong></th>
      <th class="text-center" valign="middle" rowspan="5"><strong>110%<br/><br/>60 Pontos</strong></th>
      <th class="text-center" valign="middle" rowspan="5"><strong>120%<br/><br/>100 Pontos</strong></th>
    </tr>
    <tr>
      <td>A</td>
      <td>R$ 8.000,00</td>
    </tr>
    <tr>
      <td>B</td>
      <td>R$ 6.000,00</td>
    </tr>
    <tr>
      <td>C</td>
      <td>R$ 4.000,00</td>
    </tr>
    <tr>
      <td>D</td>
      <td>R$ 2.000,00</td>
    </tr>
  </table>
</ng-template>

<ng-template #evolucao>
  <!--<table>
    <tr>
      <th class="text-center" valign="top"><strong>PERCENTUAL</strong></th>
      <th class="text-center" valign="top"><strong>PONTOS (até 99,99% de performance)</strong></th>
      <th class="text-center" valign="top"><strong>PONTOS (acima de 100% de performance)</strong></th>
    </tr>
    <tr>
      <td>De 0,00 p.p a 1,99 p.p.</td>
      <td class="text-center">0</td>
      <td class="text-center">0</td>
    </tr>
    <tr>
      <td>De 2,0 p.p a 3,99 p.p</td>
      <td class="text-center">25</td>
      <td class="text-center">100</td>
    </tr>
    <tr>
      <td>De 4,0 p.p a 5,99 p.p</td>
      <td class="text-center">50</td>
      <td class="text-center">200</td>
    </tr>
    <tr>
      <td>De 6,0 p.p a 8,99 p.p</td>
      <td class="text-center">75</td>
      <td class="text-center">250</td>
    </tr>
    <tr>
      <td>A partir de 9,00 p.p.</td>
      <td class="text-center">100</td>
      <td class="text-center">300</td>
    </tr>

  </table>-->
</ng-template>

<ng-template #oleoMaxiPerformance>
  <table>
    <tr>
      <th class="text-center" valign="top"><strong>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</strong></th>
      <th class="text-center" valign="top"><strong>PONTUAÇÃO</strong></th>
    </tr>
    <tr>
      <td>De 100% a 109,99%</td>
      <td class="text-center">100</td>
    </tr>
    <tr>
      <td>De 110% a 119,99%</td>
      <td class="text-center">150</td>
    </tr>
    <tr>
      <td>Acima de 120%</td>
      <td class="text-center">200</td>
    </tr>
  </table>
</ng-template>
<ng-template #stp>
  <table>
    <tr>
      <th class="text-center" valign="top"><strong>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</strong></th>
      <th class="text-center" valign="top"><strong>PONTUAÇÃO</strong></th>
    </tr>
    <tr>
      <td>De 0% a 84,99%</td>
      <td class="text-center">0</td>
    </tr>
    <tr>
      <td>De 85% a 89,99%</td>
      <td class="text-center">40</td>
    </tr>
    <tr>
      <td>De 90% a 94,99%</td>
      <td class="text-center">80</td>
    </tr>
    <tr>
      <td>De 95% a 99,99%</td>
      <td class="text-center">110</td>
    </tr>
    <tr>
      <td>100% Acima</td>
      <td class="text-center">150</td>
    </tr>
  </table>
</ng-template>

<ng-template #accessorios>
  <table>
    <tr>
      <th class="text-center" valign="top"><strong>PERCENTUAL</strong></th>
      <th class="text-center" valign="top"><strong>PONTOS <br>(até 99,99% de performance)</strong></th>
      <th class="text-center" valign="top"><strong>PONTUAÇÃO <br>(até 100,00% de performance)</strong></th>
    </tr>
    <tr>
      <td>De 0,00 p.p a 1,99 p.p.</td>
      <td class="text-center">0</td>
      <td class="text-center">0</td>
    </tr>
    <tr>
      <td>De 2,0 p.p a 3,99 p.p.</td>
      <td class="text-center">12,5</td>
      <td class="text-center">50</td>
    </tr>
    <tr>
      <td>De 4,0 p.p a 5,99 p.p.</td>
      <td class="text-center">25</td>
      <td class="text-center">75</td>
    </tr>
    <tr>
      <td>De 6,0 p.p a 8,99 p.p.</td>
      <td class="text-center">37,5</td>
      <td class="text-center">100</td>
    </tr>
    <tr>
      <td>A partir de 9,00 p.p.</td>
      <td class="text-center">50</td>
      <td class="text-center">200</td>
    </tr>

  </table>
</ng-template>

<ng-template #evopecas>
  <table>
    <tr>
      <th class="text-center" valign="top"><strong>PERCENTUAL</strong></th>
      <th class="text-center" valign="top"><strong>PONTOS <br>(até 99,99% de performance)</strong></th>
      <th class="text-center" valign="top"><strong>PONTUAÇÃO <br>(até 100,00% de performance)</strong></th>
    </tr>
    <tr>
      <td>De 0,00 p.p a 1,99 p.p.</td>
      <td class="text-center">0</td>
      <td class="text-center">0</td>
    </tr>
    <tr>
      <td>De 2,0 p.p a 3,99 p.p.</td>
      <td class="text-center">25</td>
      <td class="text-center">100</td>
    </tr>
    <tr>
      <td>De 4,0 p.p a 5,99 p.p.</td>
      <td class="text-center">50</td>
      <td class="text-center">200</td>
    </tr>
    <tr>
      <td>De 6,0 p.p a 8,99 p.p.</td>
      <td class="text-center">75</td>
      <td class="text-center">250</td>
    </tr>
    <tr>
      <td>A partir de 9,00 p.p.</td>
      <td class="text-center">100</td>
      <td class="text-center">300</td>
    </tr>

  </table>
</ng-template>

<ng-template #posvendas>
  <table>
    <tr>
      <th class="text-center" valign="top"><strong>ÍNDICE</strong></th>
      <th class="text-center" valign="top"><strong>PONTOS</strong></th>
    </tr>
    <tr>
      <td>≥80,00%</td>
      <td class="text-center">600</td>

    </tr>
    <tr>
      <td>70,00% a 79,99%</td>
      <td class="text-center">300</td>

    </tr>
    <tr>
      <td>60,00% a 69,99%</td>
      <td class="text-center">200</td>

    </tr>
    <tr>
      <td>50,00% a 59,00%</td>
      <td class="text-center">50</td>

    </tr>
    <tr>
      <td>≤50,00%</td>
      <td class="text-center">0</td>

    </tr>


  </table>
</ng-template>

<ng-template #petrostp>
  <table>
    <tr>
      <th>PERCENTUAL DE CUMPRIMENTO DO OBJETIVO</th>
      <th class="text-center">PONTUAÇÃO</th>
    </tr>
    <tr>
      <td>0,00% a 84,99%</td>
      <td class="text-center"><b>0</b></td>
    </tr>
    <tr>
      <td>85% a 89,99%</td>
      <td class="text-center"><b>40</b></td>
    </tr>
    <tr>
      <td>90,00% a 94,99%</td>
      <td class="text-center"><b>80</b></td>
    </tr>
    <tr>
      <td>95% a 99,99%</td>
      <td class="text-center"><b>110</b></td>
    </tr>
    <tr>
      <td>100% Acima</td>
      <td class="text-center"><b>150</b></td>
    </tr>
    <tr>
      <td>50% do Objetivo FAT.em G - 001 - 780 - Pontuaça Adicional</td>
      <td class="text-center"><b>50</b></td>
    </tr>
  </table>
</ng-template>

<ng-template #autonomia>
  <table>
    <tr>
      <th>PONTUAÇÂO</th>
      <th class="text-center">CLASSIFICAÇÃO</th>
    </tr>
    <tr>
      <td>500 PONTOS</td>
      <td class="text-center"><b>DNs classificados como A+ na medição trimestral do Programa Autonomia </b></td>
    </tr>
    <tr>
      <td>250 PONTOS</td>
      <td class="text-center"><b>DNs classificados como A na medição trimestral do Programa Autonomia</b></td>
    </tr>
    <tr>
      <td>125 PONTOS</td>
      <td class="text-center"><b>DNs classificados como B na medição trimestral do Programa Autonomia</b></td>
    </tr>
    <tr>
      <td>000 PONTOS</td>
      <td class="text-center"><b>DNs classificados como C na medição trimestral do Programa Autonomia</b></td>
    </tr>


  </table>
</ng-template>

<ng-template #empty>

</ng-template>
